import Container from "@material-ui/core/Container";
import { ChartData, ChartDataset } from "chart.js";
import * as React from "react"
import { Layout } from "../components/layout";
import { ScoreDevelopment } from "../components/score-development";
import { getSortedPredictions } from "../libs/helpers";
import { predictions, tables } from "../libs/models";


const ProgressionPage: React.FC = () => {
  const labels = tables.map((table) => table.round);
  const players: {[key: string]: ChartDataset} = {};
  predictions.forEach((prediction) => players[prediction.name] = {
    label: prediction.name,
    data: [],
    backgroundColor: prediction.color,
    borderColor: prediction.color
  });
  tables.forEach((table) => getSortedPredictions(table).forEach((score, i) => players[score.name].data.push(i+1)));
  const datasets = Object.values(players);
  const data: ChartData = {
    labels,
    datasets
  };

  return (
    <Layout>
      <Container>
        <h1>Udviklingen i stillingen</h1>
        <ScoreDevelopment chartData={data}/>
      </Container>
    </Layout>
  )
}

export default ProgressionPage;
